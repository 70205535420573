import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import Contact from '../components/Contact'

class GarmentSupplyAndPrint extends React.Component {
  render() {
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location}>
        <Helmet title={`Garment Supply & Print | ${siteTitle}`} />
        <header className="garment-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 garment">
                <h1>Garment Supply &amp; Print</h1>
                <p>
                  Choose products from our partners collection below, then{' '}
                  <a href="mailto:info@dgvisualmedia.com">email</a> the product
                  code along with your print/embroidery specs to us and we’ll
                  reply with a proof &amp; quote.
                </p>
              </div>
            </div>
          </div>
        </header>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <hr className="blue" />
              <hr className="yellow" />
            </div>
          </div>
        </div>
        <div className="iframe-wrapper">
          <iframe src="https://www.fullcollection.com/" />
        </div>
        <Contact />
      </Layout>
    )
  }
}

export default GarmentSupplyAndPrint

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
  }
`
